import Layout from '@/layouts';
import KioskPageLayout from '@/layouts/kioskPageLayout';
import LandingPageLayout from '@/layouts/landingPageLayout';
import ErrorSection from '@/modules/error-page/errorSection';
export default function Custom404() {
	return <ErrorSection statusCode={404} />;
}

Custom404.getLayout = function getLayout(page, pageProps) {
	const resolvedUrl = pageProps.resolvedUrl || '';

	if (resolvedUrl.startsWith('/kiosk')) {
		return <KioskPageLayout>{page}</KioskPageLayout>;
	}

	if (resolvedUrl.startsWith('/page')) {
		return <LandingPageLayout>{page}</LandingPageLayout>;
	}

	return <Layout>{page}</Layout>;
};
